export class AuthStateModel {
    constructor(verifier, code_challenge, state) {
        this.verifier = verifier;
        this.code_challenge = code_challenge;
        this.state = state;
    }

    verifier: string = null;
    code_challenge: string = null;
    state: string = null;
}