import crypto from 'crypto'


export const PKCEMixin = {
    methods: {
        makeUniqueIdentifier(length) {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        sha256(buffer) {
            return this.base64URLEncode(crypto.createHash('sha256').update(buffer).digest());
        },
        base64URLEncode(str) {
            return str.toString('base64')
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=/g, '');
        },
        serialize(obj) {
            let str = [];
            for (let p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(p + "=" + obj[p]);
                }
            return str.join("&");
        }
    }
};